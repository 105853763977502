import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  adminRoutes,
  clientRoutes,
  interpreterRoutes,
  notLoggedInRoutes
} from 'src/app/app.routes';
import { jwtDecode } from 'jwt-decode';

export function getAccessToken(): string | null {
  return localStorage.getItem('access_token');
}

export function setAccessToken(token: string): void {
  return localStorage.setItem('access_token', token);
}

export function removeAccessToken(): void {
  return localStorage.removeItem('access_token');
}

export function getRefreshToken(): string | null {
  return localStorage.getItem('refresh_token');
}

export function setRefreshToken(token: string): void {
  return localStorage.setItem('refresh_token', token);
}

export function removeRefreshToken(): void {
  return localStorage.removeItem('refresh_token');
}

interface DolmatchJwtPayload {
  uid: string; // user id
  sln: string; // lastname
  aty: string; // roles
  sfn: string; // firstname
  sub: string; // email
  iat: number; // creation time
  exp: number; // expiration time
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router) {
    if (this.isInterpreter()) {
      this.router.resetConfig(interpreterRoutes);
    } else if (this.isClient()) {
      this.router.resetConfig(clientRoutes);
    } else if (this.isAdmin()) {
      this.router.resetConfig(adminRoutes);
    } else {
      this.router.resetConfig(notLoggedInRoutes);
    }
  }

  public isInterpreter(): boolean {
    const token = getAccessToken();
    const role = token ? jwtDecode<DolmatchJwtPayload>(token)?.aty ?? '' : '';
    return role === 'ROLE_INTERPRETER';
  }

  public isClient(): boolean {
    const token = getAccessToken();
    const role = token ? jwtDecode<DolmatchJwtPayload>(token)?.aty ?? '' : '';
    return role === 'ROLE_CLIENT';
  }

  public isAdmin(): boolean {
    const token = getAccessToken();
    const role = token ? jwtDecode<DolmatchJwtPayload>(token)?.aty ?? '' : '';
    return role === 'ROLE_ADMIN';
  }

  public getAccessTokenIfValid(): any | undefined {
    return this.getTokenIfValid(getAccessToken());
  }

  public getRefreshTokenIfValid(): any | undefined {
    return this.getTokenIfValid(getRefreshToken());
  }

  private getTokenIfValid(encodedToken: string | null): any | undefined {
    if (encodedToken === null) {
      return undefined;
    }
    const token = jwtDecode(encodedToken);
    if (token.exp !== undefined && new Date(token.exp * 1000) > new Date()) {
      return token;
    } else {
      return undefined;
    }
  }

  public getName(): string {
    const token = getAccessToken();
    const firstName = token ? jwtDecode<DolmatchJwtPayload>(token)?.sfn ?? '' : '';
    const lastName = token ? jwtDecode<DolmatchJwtPayload>(token)?.sln ?? '' : '';
    return `${firstName} ${lastName}`;
  }
}

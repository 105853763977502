import { InterpreterResponseStatus } from '../../interpreter/contract/interpreter-response-status.model';
import {
  ClientInterpreterSummary,
  ClientInterpreterSummaryDto
} from '../interpreter/client-interpreter-summary.model';

export interface InterpreterResponseDto {
  readonly responseStatus: InterpreterResponseStatus;
  readonly suggestedTime: string;
  readonly interpreter: ClientInterpreterSummaryDto;
  readonly hasBeenAskedBefore: boolean;
}

export class InterpreterResponse {
  responseStatus: InterpreterResponseStatus;
  suggestedTime: Date | null;
  interpreter: ClientInterpreterSummary;
  hasBeenAskedBefore: boolean;

  constructor(dto: InterpreterResponseDto) {
    this.responseStatus = dto.responseStatus;
    this.suggestedTime = dto.suggestedTime ? new Date(dto.suggestedTime) : null;
    this.interpreter = new ClientInterpreterSummary(dto.interpreter);
    this.hasBeenAskedBefore = dto.hasBeenAskedBefore;
  }
}

import { DateTime } from 'luxon';
import { Gender } from './gender.model';

export interface ClientInterpreterSummaryDto {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly birthYear: string;
  readonly gender: Gender;
  readonly profession: string;
  readonly confirmed: boolean;
  readonly availableFrom?: string;
  readonly possibleUses?: string;
  readonly nextAvailability?: string;
  readonly nextAvailabilityConfirmed?: boolean;
}

export class ClientInterpreterSummary {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly birthYear: string;
  readonly gender: Gender;
  readonly profession: string;
  readonly confirmed: boolean;
  readonly availableFrom: string | null;
  readonly possibleUses: string | null;
  readonly nextAvailability: Date | null;
  readonly nextAvailabilityConfirmed: boolean | null;

  constructor(dto: ClientInterpreterSummaryDto) {
    this.id = dto.id;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.birthYear = dto.birthYear;
    this.gender = dto.gender;
    this.profession = dto.profession;
    this.confirmed = dto.confirmed;
    this.availableFrom = dto.availableFrom ?? null;
    this.possibleUses = dto.possibleUses ?? null;
    this.nextAvailability = dto.nextAvailability
      ? DateTime.fromISO(dto.nextAvailability).toJSDate()
      : null;
    this.nextAvailabilityConfirmed = dto.nextAvailabilityConfirmed ?? null;
  }
}

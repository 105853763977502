import { ChangeDetectorRef, Component, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { NgFor } from '@angular/common';

export interface AlertData {
  header: string;
  message: string;
  buttonText: string;
  page: string | undefined;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, NgFor]
})
export class AlertComponent {
  readonly alertData = inject<AlertData>(MAT_DIALOG_DATA);
  constructor(
    private dialogRef: MatDialogRef<AlertComponent>,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    setTimeout(() => this.changeDetectorRef.detectChanges(), 100);
  }

  onButtonClick(): void {
    if (this.alertData.page !== undefined) {
      void this.router.navigate(['/' + this.alertData.page]);
    }
    this.dialogRef.close();
  }
}

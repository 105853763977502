export interface AddressDto {
  readonly name: string;
  readonly street: string;
  readonly zip: string;
  readonly location: string;
}

export class Address {
  readonly name: string;
  readonly street: string;
  readonly zip: string;
  readonly location: string;

  constructor(dto: AddressDto) {
    this.name = dto.name;
    this.street = dto.street;
    this.zip = dto.zip;
    this.location = dto.location;
  }

  get googleMapsLink(): string {
    return `https://www.google.com/maps/search/?api=1&query=${
      this.name ? this.name.replace(' ', '+') + '+' : ''
    }${this.street}+${this.zip}+${this.location}`;
  }

  get addressSummary(): string {
    let lines = [];
    if (this.street) {
      lines.push(this.street);
    }
    if (this.zip) {
      lines.push(`${this.zip} ${this.location}`);
    }
    return lines.join(', ');
  }
}

import {
  BaseContractSummary,
  BaseContractSummaryDto
} from '../../base/contract/base-contract-summary.model';
import { InterpreterResponseStatus } from './interpreter-response-status.model';
import { DateTime } from 'luxon';

export interface InterpreterContractSummaryDto extends BaseContractSummaryDto {
  readonly responseStatus: InterpreterResponseStatus;
  readonly clientName: string;
  readonly selected: boolean;
  readonly expirationTime: string;
  readonly suggestedTime?: string;
}

export class InterpreterContractSummary extends BaseContractSummary {
  readonly responseStatus: InterpreterResponseStatus;
  readonly clientName: string;
  readonly selected: boolean;
  readonly expirationTime: Date;
  readonly suggestedTime?: Date | null;

  constructor(dto: InterpreterContractSummaryDto) {
    super(dto);
    this.responseStatus = dto.responseStatus;
    this.clientName = dto.clientName;
    this.selected = dto.selected;
    this.expirationTime = DateTime.fromISO(dto.expirationTime).toJSDate();
    this.suggestedTime = dto.suggestedTime
      ? DateTime.fromISO(dto.suggestedTime).toJSDate()
      : null;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientRegistrationDto } from '../../models/client/client.model';
import { InterpreterRegistrationDto } from '../../models/client/interpreter/interpreter.model';
import {
  JwtAuthenticationRequestDto,
  JwtAuthenticationResponseDto
} from '../../models/base/jwt.model';
import { catchError, map, tap } from 'rxjs/operators';
import {
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken
} from '../../../core/services/auth.service';
import { PasswordResetDto } from '../../models/base/password-reset.model';
import { Client, ClientDto } from '../../models/admin/client/client.model';
import {DateTime} from "luxon";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationDataService {
  protected readonly baseUrl = `${environment.apiRoot}`;

  cachedRefreshToken$: Observable<JwtAuthenticationResponseDto> | null = null;
  cachedRefreshTokenUpdateTime: DateTime | null = null;
  private static minSecondsUntilNextRefresh: number = 5;

  constructor(private httpClient: HttpClient) {}

  login(dto: JwtAuthenticationRequestDto): Observable<JwtAuthenticationResponseDto> {
    return this.httpClient
      .post<JwtAuthenticationResponseDto>(this.baseUrl + '/auth', dto)
      .pipe(
        tap(dto => {
          setAccessToken(dto.accessToken);
          setRefreshToken(dto.refreshToken);
        })
      );
  }

  requestPasswordReset(email: string): Observable<void> {
    return this.httpClient.post<void>(this.baseUrl + '/request-password-reset', email);
  }

  resetPassword(dto: PasswordResetDto): Observable<void> {
    return this.httpClient.post<void>(this.baseUrl + '/reset-password', dto);
  }

  refreshToken(): Observable<JwtAuthenticationResponseDto> {
    if (
      !this.cachedRefreshToken$
      || !this.cachedRefreshTokenUpdateTime
      || (DateTime.now().diff(this.cachedRefreshTokenUpdateTime).as('seconds') > AuthenticationDataService.minSecondsUntilNextRefresh)
    ) {
      this.cachedRefreshTokenUpdateTime = DateTime.now();
      this.cachedRefreshToken$ = this.httpClient
        .post<JwtAuthenticationResponseDto>(this.baseUrl + '/refresh', getRefreshToken())
        .pipe(
          tap(dto => {
            setAccessToken(dto.accessToken);
            setRefreshToken(dto.refreshToken);
          }),
          catchError(err => {
            if (err.status === 401) {
              removeRefreshToken();
            }
            throw err;
          }),
          shareReplay({ refCount: true })
        );
    }
    return this.cachedRefreshToken$;
  }

  registerClient(dto: ClientRegistrationDto): Observable<Client> {
    return this.httpClient
      .post<ClientDto>(this.baseUrl + '/registration/client', dto)
      .pipe(map(dto => new Client(dto)));
  }

  registerInterpreterOrAdmin(dto: InterpreterRegistrationDto): Observable<void> {
    return this.httpClient.post<void>(this.baseUrl + '/registration', dto);
  }
}

import { Address, AddressDto } from '../../base/contract/address-model';

export interface LocationDto extends AddressDto {
  readonly id: number;
}

export class Location extends Address {
  readonly id: number;

  constructor(dto: LocationDto) {
    super(dto);
    this.id = dto.id;
  }
}

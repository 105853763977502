export interface SortDto {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export class Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;

  constructor(dto: SortDto) {
    this.empty = dto.empty;
    this.sorted = dto.sorted;
    this.unsorted = dto.unsorted;
  }
}

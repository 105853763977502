export interface AgencyDto {
  readonly id: number;
  readonly name: string;
  readonly sortOrder: number;
}

export class Agency {
  readonly id: number;
  readonly name: string;
  readonly sortOrder: number;

  constructor(dto: AgencyDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.sortOrder = dto.sortOrder;
  }
}

<h2 mat-dialog-title>{{alertData.header}}</h2>
<mat-dialog-content>
  <ng-container *ngFor="let line of alertData.message.split('\n')">
    {{line}}
    <br>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onButtonClick()">{{alertData.buttonText}}</button>
</mat-dialog-actions>

import {
  InterpreterContractSummary,
  InterpreterContractSummaryDto
} from './interpreter-contract-summary.model';
import { Client, ClientDto } from './client-model';

export interface InterpreterContractDto extends InterpreterContractSummaryDto {
  readonly client?: ClientDto;
}

export class InterpreterContract extends InterpreterContractSummary {
  readonly client: ClientDto | null;

  constructor(dto: InterpreterContractDto) {
    super(dto);
    this.client = dto.client ? new Client(dto.client) : null;
  }
}

export interface ClientDto {
  readonly firstName: string;
  readonly lastName: string;
  readonly mobilePhone: string;
  readonly otherPhone: string;
}

export class Client {
  readonly firstName: string;
  readonly lastName: string;
  readonly mobilePhone: string;
  readonly otherPhone: string;

  constructor(dto: ClientDto) {
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.mobilePhone = dto.mobilePhone;
    this.otherPhone = dto.otherPhone;
  }
}

import { Component, OnInit } from '@angular/core';
import { IonApp, IonRouterOutlet, IonButton } from '@ionic/angular/standalone';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';

import { AuthService } from './core/services/auth.service';
import { IconService } from './core/services/icon.service';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { Observable, map } from 'rxjs';
import { BaseContractSummary } from './data/models/base/contract/base-contract-summary.model';
import { ClientContractSummary } from './data/models/client/contract/client-contract-summary.model';
import { InterpreterContractSummary } from './data/models/interpreter/contract/interpreter-contract-summary.model';
import { ClientContractDataService } from './data/services/client/client-contract-data.service';
import { InterpreterContractDataService } from './data/services/interpreter/interpreter-contract-data.service';
import { InterpreterResponseStatus } from './data/models/interpreter/contract/interpreter-response-status.model';
import { ContractStatus } from './data/models/base/contract/contract-status.model';
import { CommonModule } from '@angular/common';
import { first, tap } from 'rxjs/operators';
import { DeviceInfoService } from './core/services/device-info.service';
import { AlertComponent, AlertData } from './shared/components/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [CommonModule, IonButton, IonApp, IonRouterOutlet, MatIcon, RouterModule]
})
export class AppComponent implements OnInit {
  hasOpenContracts$ = this.hasOpenContracts();

  constructor(
    private authService: AuthService,
    private iconService: IconService,
    private router: Router,
    private clientContractDataService: ClientContractDataService,
    private interpreterContractDataService: InterpreterContractDataService,
    private deviceInfoService: DeviceInfoService,
    private dialog: MatDialog
  ) {
    this.iconService.registerIcons();
  }

  ngOnInit(): void {
    if (this.deviceInfoService.runningOnDevice()) {
      this.initPushNotifications();
    }
  }

  initPushNotifications(): void {
    console.log('Initializing push notifications...');
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    void PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        void PushNotifications.register();
      } else {
        console.error('Push registration not granted');
        // TODO: do we need to do anything else here?
      }
    });

    // On success, we should be able to receive notifications
    void PushNotifications.addListener('registration', (token: Token) => {
      console.log('Push registration success..sending token to backend...');
      localStorage.setItem('firebaseToken', token.value);
    });

    // Some issue with our setup and push will not work
    void PushNotifications.addListener('registrationError', (error: any) => {
      console.error('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    void PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.debug('Push received: ' + JSON.stringify(notification));
        let contractId = notification.data['contractId'];
        let page = notification.data['page'];
        let expired = notification.data['expired'] === 'true';
        this.processPushNotification(contractId, page, expired);
      }
    );

    // Method called when tapping on a notification
    void PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (actionPerformed: ActionPerformed) => {
        console.debug('Push action performed: ' + JSON.stringify(actionPerformed));
        let contractId = actionPerformed.notification.data['contractId'];
        let page = actionPerformed.notification.data['page'];
        let expired = actionPerformed.notification.data['expired'] === 'true';
        this.processPushNotification(contractId, page, expired);
      }
    );
  }

  hasOpenContracts(): Observable<boolean> {
    const observable: Observable<BaseContractSummary[]> = this.authService.isInterpreter()
      ? this.interpreterContractDataService.getPendingContracts()
      : this.clientContractDataService.getPendingContracts();
    return observable.pipe(
      tap(contracts => {
        // if we get here, we're logged in
        if (this.router.url === '/login' || this.router.url === '/registration') {
          void this.router.navigate(['/']);
        }
      }),
      map(
        contracts =>
          contracts.filter(
            contract =>
              (contract instanceof ClientContractSummary &&
                contract.status === ContractStatus.OPEN) ||
              (contract instanceof InterpreterContractSummary &&
                contract.responseStatus === InterpreterResponseStatus.OPEN)
          ).length > 0
      )
    );
  }

  private processPushNotification(
    contractId: number | undefined,
    page: string | undefined,
    expired?: boolean
  ): void {
    if (contractId) {
      if (this.authService.isInterpreter()) {
        this.interpreterContractDataService
          .fetchById(contractId)
          .pipe(first())
          .subscribe(contract => {
            const selected =
              contract.status === ContractStatus.CONFIRMED && contract.selected;
            const isNew =
              contract.status === ContractStatus.OPEN ||
              contract.status === ContractStatus.SENT;
            const cancelled = contract.status === ContractStatus.CANCELLED;
            const alertData = {
              header: isNew
                ? 'Anfrage Dolmetschauftrag'
                : cancelled
                ? 'Auftrag abgebrochen'
                : selected
                ? 'Auftrag erhalten'
                : 'Auftrag leider nicht erhalten',
              message: contract.summary,
              buttonText: selected || isNew ? 'Anzeigen' : 'OK',
              page: selected || isNew ? page : undefined
            };
            this.dialog.open(AlertComponent, {
              data: alertData
            });
          });
      } else if (this.authService.isClient()) {
        this.clientContractDataService
          .getById(contractId)
          .pipe(first())
          .subscribe(contract => {
            let cancelled = contract.status === ContractStatus.CANCELLED;
            const alertData: AlertData = {
              header: cancelled
                ? 'Auftrag wurde abgebrochen'
                : expired
                ? 'Zeit für Antwort abgelaufen'
                : 'Antworten erhalten',
              message: contract.summary,
              buttonText: 'Anzeigen',
              page: page
            };
            void this.dialog.open(AlertComponent, {
              data: alertData
            });
          });
      }
    } else if (page) {
      if (page === 'availability') {
        const alertData: AlertData = {
          header: 'Verfügbarkeit bestätigen',
          message: 'Bitte bestätigen Sie Ihre Verfügbarkeit für die nächste Woche.',
          buttonText: 'Bestätigen',
          page: page
        };
        void this.dialog.open(AlertComponent, {
          data: alertData
        });
      } else {
        void this.router.navigate(['/' + page]);
      }
    }
  }
}

import { Sort, SortDto } from './sort.model';

export interface PageDto<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  sort: SortDto;
  totalElements: number;
  totalPages: number;
}

export class Page<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  pageIndex: number;
  numberOfElements: number;
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;

  constructor(dto: PageDto<any>, model: new (arg: any) => T) {
    this.content = dto.content.map(obj => new model(obj));
    this.empty = dto.empty;
    this.first = dto.first;
    this.last = dto.last;
    this.pageIndex = dto.number;
    this.numberOfElements = dto.numberOfElements;
    this.size = dto.size;
    this.sort = new Sort(dto.sort);
    this.totalElements = dto.totalElements;
    this.totalPages = dto.totalPages;
  }

  append(page: Page<T>): void {
    if (!page || this.pageIndex + 1 !== page.pageIndex) {
      return;
    }
    this.content = [...this.content, ...page.content];
    this.empty = this.empty && page.empty;
    this.first = this.first || page.first;
    this.last = this.last || page.last;
    this.pageIndex = page.pageIndex;
    this.numberOfElements = this.numberOfElements + page.numberOfElements;
    this.size = page.size;
    this.sort = page.sort;
    this.totalElements = page.totalElements;
    this.totalPages = page.totalPages;
  }

  static create<T, S>(
    content: S[],
    total: number,
    page: number,
    size: number,
    model: new (arg: S) => T
  ): Page<T> {
    return new Page(
      {
        content,
        empty: total === 0,
        first: page === 0,
        last: total <= (page + 1) * size,
        number: page,
        numberOfElements: size,
        size,
        totalElements: total,
        totalPages: Math.ceil(total / size),
        sort: {
          empty: false,
          unsorted: false,
          sorted: true
        }
      },
      model
    );
  }
}

export interface InterpreterDto {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly mobilePhone: string;
  readonly otherPhone: string;
}

export class Interpreter {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly mobilePhone: string;
  readonly otherPhone: string;

  constructor(dto: InterpreterDto) {
    this.id = dto.id;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.mobilePhone = dto.mobilePhone;
    this.otherPhone = dto.otherPhone;
  }
}

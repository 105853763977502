import { DateTime } from 'luxon';
import {
  BaseContractSummary,
  BaseContractSummaryDto
} from '../../base/contract/base-contract-summary.model';

export interface ClientContractSummaryDto extends BaseContractSummaryDto {
  readonly pendingInterpreters: string[];
  readonly interpreterName: string;
  readonly expirationTime: string;
}

export class ClientContractSummary extends BaseContractSummary {
  pendingInterpreters: string[];
  interpreterName: string;
  expirationTime: Date | null;

  constructor(dto: ClientContractSummaryDto) {
    super(dto);
    this.pendingInterpreters = dto.pendingInterpreters;
    this.interpreterName = dto.interpreterName;
    this.expirationTime = dto.expirationTime
      ? DateTime.fromISO(dto.expirationTime).toJSDate()
      : null;
  }
}

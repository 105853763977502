import {
  ClientContractSummary,
  ClientContractSummaryDto
} from './client-contract-summary.model';
import {
  InterpreterResponse,
  InterpreterResponseDto
} from './interpreter-response.model';
import { Interpreter, InterpreterDto } from './interpreter-model';

export interface ClientContractDto extends ClientContractSummaryDto {
  interpreterResponses: InterpreterResponseDto[];
  selectedInterpreter?: InterpreterDto;
}

export class ClientContract extends ClientContractSummary {
  interpreterResponses: InterpreterResponse[];
  selectedInterpreter: Interpreter | null;

  constructor(dto: ClientContractDto) {
    super(dto);
    this.interpreterResponses = dto.interpreterResponses.map(
      r => new InterpreterResponse(r)
    );
    this.selectedInterpreter = dto.selectedInterpreter
      ? new Interpreter(dto.selectedInterpreter)
      : null;
  }
}

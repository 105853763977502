import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, shareReplay, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  InterpreterContractSummary,
  InterpreterContractSummaryDto
} from '../../models/interpreter/contract/interpreter-contract-summary.model';
import { Page, PageDto } from '../../models/base/page/page.model';
import { PageParams } from '../../models/base/page/page-params.model';
import HttpClientUtils from 'src/app/shared/utils/http-client-utils';
import {
  InterpreterContract,
  InterpreterContractDto
} from '../../models/interpreter/contract/interpreter-contract.model';
import { Platform } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { Badge } from '@capawesome/capacitor-badge';
import { InterpreterResponseStatus } from '../../models/interpreter/contract/interpreter-response-status.model';

@Injectable({
  providedIn: 'root'
})
export class InterpreterContractDataService {
  protected readonly baseUrl = `${environment.apiRoot}/interpreter/contract`;

  cacheInvalidator$ = new BehaviorSubject<void>(undefined);

  constructor(private httpClient: HttpClient, private platform: Platform) {}

  getPendingContracts(): Observable<InterpreterContractSummary[]> {
    return this.getContracts('pending').pipe(
      tap(pendingContracts => {
        const countOpenContracts = pendingContracts.filter(
          pc => pc.responseStatus === InterpreterResponseStatus.OPEN
        ).length;
        if (this.platform.is('capacitor')) {
          void Badge.set({
            count: countOpenContracts
          });
        }
      })
    );
  }

  getFutureContracts(): Observable<InterpreterContractSummary[]> {
    return this.getContracts('future');
  }

  getArchivedContracts(params: PageParams): Observable<Page<InterpreterContractSummary>> {
    return this.cacheInvalidator$.pipe(
      switchMap(() =>
        this.httpClient
          .get<PageDto<InterpreterContractSummaryDto>>(`${this.baseUrl}/archived`, {
            params: HttpClientUtils.toHttpParams(params)
          })
          .pipe(
            map(
              dto => new Page<InterpreterContractSummary>(dto, InterpreterContractSummary)
            ),
            shareReplay({ refCount: true })
          )
      )
    );
  }

  private getContracts(type: string): Observable<InterpreterContractSummary[]> {
    return this.cacheInvalidator$.pipe(
      switchMap(() =>
        this.httpClient.get<InterpreterContractSummaryDto[]>(`${this.baseUrl}/${type}`)
      ),
      map(dto => dto.map(data => new InterpreterContractSummary(data))),
      shareReplay({ refCount: true })
    );
  }

  fetchById(id: number): Observable<InterpreterContract> {
    return this.cacheInvalidator$.pipe(
      switchMap(() =>
        this.httpClient.get<InterpreterContractDto>(`${this.baseUrl}/${id}`)
      ),
      map(dto => new InterpreterContract(dto))
    );
  }

  sendCalendarInvite(id: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/${id}/send`, {});
  }

  confirmById(id: number, time?: string): Observable<InterpreterContractSummary> {
    return this.cacheInvalidator$.pipe(
      switchMap(() =>
        this.httpClient.put<InterpreterContractSummaryDto>(
          `${this.baseUrl}/${id}/confirm`,
          {
            startTime: time
          }
        )
      ),
      map(dto => {
        this.cacheInvalidator$.next();
        return new InterpreterContractSummary(dto);
      })
    );
  }

  rejectById(id: number): Observable<InterpreterContractSummary> {
    return this.cacheInvalidator$.pipe(
      switchMap(() =>
        this.httpClient.put<InterpreterContractSummaryDto>(
          `${this.baseUrl}/${id}/reject`,
          {}
        )
      ),
      map(dto => {
        this.cacheInvalidator$.next();
        return new InterpreterContractSummary(dto);
      })
    );
  }

  markContractAsCancelled(id: number): Observable<InterpreterContract> {
    return this.httpClient
      .put<InterpreterContractDto>(`${this.baseUrl}/${id}/mark-cancelled`, {})
      .pipe(
        map(data => {
          this.cacheInvalidator$.next();
          return new InterpreterContract(data);
        })
      );
  }
}

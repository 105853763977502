import { ApplicationConfig } from '@angular/core';
import { RouteReuseStrategy, provideRouter } from '@angular/router';

import { baseRoutes } from './app.routes';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_PROGRESS_BAR_DEFAULT_OPTIONS } from '@angular/material/progress-bar';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MAT_BUTTON_CONFIG } from '@angular/material/button';
import { HTTP_INTERCEPTOR_PROVIDERS } from './interceptors';
import { DolmatchLuxonDateAdapter } from './shared/utils/dolmatch-luxon-date-adapter';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(baseRoutes),
    importProvidersFrom(BrowserAnimationsModule, MatNativeDateModule),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: MAT_BUTTON_CONFIG,
      useValue: {
        disableRipple: true
      }
    },
    {
      provide: MAT_PROGRESS_BAR_DEFAULT_OPTIONS,
      useValue: { mode: 'indeterminate' }
    },
    {
      provide: DateAdapter,
      useClass: DolmatchLuxonDateAdapter
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-CH'
    },
    provideIonicAngular(),
    importProvidersFrom(
      IonicModule.forRoot({ mode: 'ios', innerHTMLTemplatesEnabled: true })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideAnimationsAsync('noop'),
    HTTP_INTERCEPTOR_PROVIDERS
  ]
};

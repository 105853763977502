import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {
  private static UNKNOWN_BROWSER = 'Unknown Browser';
  private version = 'unknown';
  private name = 'unknown';

  constructor(private platform: Platform) {
    void Device.getInfo().then(info => {
      this.version = info.osVersion;
      this.name = info.name ?? 'unknown';
    });
  }

  /**
   * Get device name
   * @return human friendly device name if available, 'unknown' otherwise, 'browser' if running in browser
   */
  getName(): string {
    if (!this.isBrowser()) {
      return this.name;
    } else {
      return 'browser';
    }
  }

  async getAppVersion(): Promise<string> {
    if (!this.isBrowser()) {
      const info = await App.getInfo();
      return Promise.resolve(info.version);
    } else {
      // Running in browser
      return 'unknown';
    }
  }

  // Use this method to check if App is running on a real device before using native plugins
  runningOnDevice(): boolean {
    return this.platform.is('cordova');
  }

  getScreenWidth(): number {
    return this.platform.width();
  }

  getScreenHeight(): number {
    return this.platform.height();
  }

  getScreenSize(): [number, number] {
    return [this.getScreenWidth(), this.getScreenHeight()];
  }

  isLandscape(): boolean {
    return this.platform.isLandscape();
  }

  isProd(): boolean {
    return environment.production;
  }

  isIOS(): boolean {
    return this.platform.is('ios');
  }

  isAndroid(): boolean {
    return this.platform.is('android');
  }

  // negation of isBrowser is incorrect since it can be Mobile Browser
  isMobile(): boolean {
    return this.isIOS() || this.isAndroid();
  }

  isBrowser(): boolean {
    return Capacitor.getPlatform() === 'web';
  }

  isMobileBrowser(): boolean {
    return this.isBrowser() && this.isMobile();
  }

  getPlatforms(): string[] {
    return this.platform.platforms();
  }

  getOSVersion(): string {
    return this.version;
  }

  getBrowserVersion(): string {
    const chrome = navigator.userAgent.match(/Chrome\/.*\s/);
    const firefox = navigator.userAgent.match(/Firefox\/.*/);
    const safari = navigator.userAgent.match(/Version\/.*\s/);
    if (chrome && chrome.length > 0) {
      return chrome[0];
    } else if (firefox && firefox.length > 0) {
      return firefox[0];
    } else if (safari && safari.length > 0) {
      return safari[0];
    } else {
      return DeviceInfoService.UNKNOWN_BROWSER;
    }
  }
}

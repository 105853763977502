import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timeout } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent, AlertData } from '../shared/components/alert/alert.component';

@Injectable()
export class CheckConnectionInterceptor implements HttpInterceptor {
  static loadingTimeoutSeconds = 15;
  isDialogShown: boolean = false;
  constructor(private dialog: MatDialog) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(environment.apiRoot)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      timeout(CheckConnectionInterceptor.loadingTimeoutSeconds * 1000),
      catchError(err => {
        if (
          err.name === 'TimeoutError' ||
          (err instanceof HttpErrorResponse &&
            [0, 502, 503, 504].indexOf(err.status) > -1)
        ) {
          this.showDialog(err.status);
          throw Error('REQUEST_TIMEOUT');
        } else {
          throw err;
        }
      })
    );
  }

  private showDialog(statusCode: number): void {
    if (!this.isDialogShown) {
      this.isDialogShown = true;
      const alertData: AlertData = {
        header: 'Verbindungsprobleme',
        message:
          statusCode > 500
            ? 'Der Server ist momentan nicht verfügbar. Bitte versuchen Sie es später erneut.'
            : 'Es konnte keine Verbindung zum Server hergestellt werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
        buttonText: 'Neu laden',
        page: undefined
      };
      this.dialog
        .open(AlertComponent, {
          data: alertData
        })
        .afterClosed()
        .subscribe(() => {
          this.isDialogShown = false;
          location.reload();
        });
    }
  }
}
